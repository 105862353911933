import React, { useContext, useState, useRef, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import axios from "axios"

import Layout from "../Layout/Layout"
import Section from "../Elements/Section"
import Container from "../Layout/Container"
import ActionButtons from "../Elements/ActionButtons"
import Loading from "../Elements/Loading"
import UploadGuidelines from "../Upload/UploadGuidelines"
import UploadDropzone from "../Upload/UploadDropzone/index"
import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"
import DocumentsCardRow from "../Cart/DocumentsCard/DocumentsCardRow"
import ProofOfPaymentBankDetails from "./components/ProofOfPaymentBankDetails"
import UploadErrorNotification from "../Upload/Dropzone/UploadErrorNotification"
import ProofOfPaymentPersonalDetails from "./components/ProofOfPaymentPersonalDetails"

import { AppContext } from "../../context/AppContext"
import guidelines from "./utils/uploadGuidelines.json"
import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "../Upload/services/uploadDocuments"
import { ProofOfPaymentContext } from "./ProofOfPaymentContext/ProofOfPaymentContext"

const MAX_FILE_SIZE_IN_BYTES = 4000000
const MAX_FILE_COUNT = 3
const isFullSize = false

const ProofOfPayment = ({ location }) => {
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const urlSearchParams = new URLSearchParams(location.search)
  const zendeskId = urlSearchParams.get("z") || ""
  const { proofOfPaymentState, proofOfPaymentDispatch } = useContext(
    ProofOfPaymentContext
  )
  const [filesUploaded, setFilesUploaded] = useState(documents)
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null)
  const data = useProofOfPaymentIcon()
  const proofOfPaymentIcon = data?.document?.childImageSharp?.fixed
  const [hasOrderBeenCancelled, setHasOrderBeenCancelled] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  useEffect(async () => {
    setIsDataLoading(true)

    if (!!zendeskId) {
      const apiKey = process.env.GATSBY_AIRTABLE_API_KEY
      const baseId = process.env.GATSBY_AIRTABLE_ORDERS_TABLE_ID
      const tableName = process.env.GATSBY_AIRTABLE_ORDERS_TABLE_NAME

      try {
        const airtable = axios.create({
          baseURL: `https://api.airtable.com/v0/${baseId}`,
          headers: {
            Authorization: "Bearer " + apiKey,
            "Content-Type": "application/json",
          },
        })

        let results = await airtable.get(
          `/${tableName}/?filterByFormula=${encodeURIComponent(
            `{ZendeskID}=${zendeskId}`
          )}`
        )

        if (
          results?.data?.records.length > 0 &&
          results?.data?.records[0].fields["OrderStatus"] !== "00 Cancelled"
        ) {
          setHasOrderBeenCancelled(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    setIsDataLoading(false)
  }, [])

  const handleDeleteDocument = (index) => {
    const { documents } = proofOfPaymentState
    let documentsList = [...documents]
    documentsList.splice(index, 1)
    proofOfPaymentDispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList],
    })

    let tempFilesUploaded = [...filesUploaded]
    tempFilesUploaded.splice(index, 1)
    setFilesUploaded(tempFilesUploaded)
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const handleFileRead = (event) => {
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES)
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            "pop",
            setFilesUploaded,
            null,
            handleAlreadyUploaded(setNotifications),
            proofOfPaymentDispatch,
            i
          )
        else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 4MB. Please upload a file or photo less than 4MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  const handleSubmitProofOfPayment = async () => {
    setLoading(true)
    // let filesUploaded = await Promise.all(
    //   proofOfPaymentState?.documents?.map(async (document) => {
    //     const b64Data = document.path.split(",")[1]
    //     const contentType = document.path.split(",")[0].match(/:(.*?);/)[1]

    //     const fileResponse = await zendeskApi().post(
    //       `/uploads.json?filename=${document.filename}`,
    //       b64toBlob(b64Data, contentType),
    //       { headers: { "Content-Type": "application/binary" } }
    //     )

    //     return {
    //       ...fileResponse?.data?.upload?.attachment,
    //       token: fileResponse?.data?.upload?.token,
    //     }
    //   })
    // )
    await axios.post(
      process.env.GATSBY_ENV === "production"
        ? process.env.GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK
        : process.env.GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK_DEV,
      {
        documents: proofOfPaymentState?.documents,
        uploadTokens: proofOfPaymentState?.documents?.map(
          (fileUploaded) => fileUploaded.token
        ),
        zendeskId,
      }
    )
    setLoading(false)
    navigate("/epharmacy/proof-of-payment/complete")
    // upload files to airtable and zendesk
  }

  return (
    <Layout
      title="Payment Form"
      seoTitle="Payment Form"
      subtitle="Take a photo or upload a scanned copy of the payment confirmation from your bank."
    >
      <Container isCentered>
        {isDataLoading ? (
          <div className="is-flex justify-center">
            <Loading size={25} type="circle" />
          </div>
        ) : (
          <div>
            <ProofOfPaymentBankDetails
              hasOrderBeenCancelled={hasOrderBeenCancelled}
            />

            {!hasOrderBeenCancelled ? (
              <Formik
                initialValues={{ ...proofOfPaymentState }}
                onSubmit={handleSubmitProofOfPayment}
              >
                {() => (
                  <Form>
                    <ProofOfPaymentPersonalDetails
                      hasOrderBeenCancelled={hasOrderBeenCancelled}
                    />
                    <Section
                      title="Upload Proof Of Payment"
                      className="mt-1 mb-4"
                    >
                      <UploadGuidelines guidelines={guidelines} />
                      {filesUploaded.length < 3 ? (
                        <UploadDropzone
                          fileInputRef={fileInputRef}
                          handleFileChooser={openFileChooser}
                          filesUploaded={filesUploaded}
                          handleFileRead={handleFileRead}
                          notifications={notifications}
                          icon={proofOfPaymentIcon}
                          label="Upload Proof of Payment"
                        />
                      ) : null}
                      <div className="my-1">
                        {filesUploaded?.map((file, index) => (
                          <DocumentsCardRow
                            fileName={file.name}
                            oldFileName={file.oldname}
                            index={index}
                            handleDeleteDocument={handleDeleteDocument}
                            file={file}
                            isFullSize={isFullSize}
                            showOldFileName
                            showDeleteButton
                            isClickable
                          />
                        ))}
                      </div>
                    </Section>
                    <ActionButtons
                      submit={{
                        label: "Submit",
                        loading,
                        disabled: proofOfPaymentState?.documents?.length < 1,
                      }}
                    />
                  </Form>
                )}
              </Formik>
            ) : null}
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default ProofOfPayment
